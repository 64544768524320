import ls from "local-storage";
import Cryptr from "cryptr";
import md5 from "react-native-md5";
import dotenv from "dotenv";
// import process from "process";
export const BUILD_VERSION = "V11.21.9";

// console.log("procces : ", dotenv.config({ path: "../../../.env" }).URL);

// const URL1 = process.env.URL;

// console.log("first url1 : ", URL1);

//export const URL = "http://localhost:3000";


export const URL = process.env.REACT_APP_URL;

export const BASE_URL = URL + process.env.REACT_APP_BASE_URL;

export const SERVER_PRINT = process.env.REACT_APP_SERVER_PRINT;

export const BASE_URL_PHP = process.env.REACT_APP_BASE_URL_PHP;

export const BASE_URL_LIVRAISON = process.env.REACT_APP_BASE_URL_LIVRAISON;

export const BASE_URL_OLD_BO = process.env.REACT_APP_BASE_URL_OLD_BO;

export const BASE_URL_POLE_PARA = process.env.REACT_APP_BASE_URL_POLE_PARA;

export const BASE_URL_DELIVERECT = process.env.REACT_APP_BASE_URL_DELIVERECT;

export const ACCOUNT_CAISSE_POLEPARA =
  process.env.REACT_APP_ACCOUNT_CAISSE_POLEPARA;

export const ACCOUNT_CAISSE_SAID = process.env.REACT_APP_ACCOUNT_CAISSE_SAID;

export const SECRET_KEY_ENCRYPT =
  URL + process.env.REACT_APP_SECRET_KEY_ENCRYPT;

export const NUMBER_ROWS = process.env.REACT_APP_NUMBER_ROWS;

export const DEVICE = process.env.REACT_APP_DEVICE;

export const NUMBER_DECLINAISON = process.env.REACT_APP_NUMBER_DECLINAISON;

export const NUMBER_ROWS_ACTIVITE = process.env.REACT_APP_NUMBER_ROWS_ACTIVITE;

export const ADMIN_PASSWORD = "94ee18a7d434aca6d80f27d06b8c3a80";

export const DELETE_ALL_PASSWORD = process.env.REACT_APP_DELETE_ALL_PASSWORD;

export const URL_NOT_ALLOWED_PAGE = process.env.REACT_APP_URL_NOT_ALLOWED_PAGE;

export const ADMIN_ID_CAISSE = process.env.REACT_APP_ADMIN_ID_CAISSE;

export const NUMBER_ROWS_PRODUIT = process.env.REACT_APP_NUMBER_ROWS_PRODUIT;

export const LIMIT_PRODUCTS_EXPRESS =
  process.env.REACT_APP_LIMIT_PRODUCTS_EXPRESS;

//image size in byte :1000000 = 1 megabyte

export const IMAGE_SIZE = process.env.REACT_APP_IMAGE_SIZE;


export const GRAND_STOCK_CAISSES_LIST = [531,2111, 2112 , 2113 , 572, 573, 743,699,693,821,1481,1482,1483,1484,1485,897]


export const PATH_IMAGES = URL + process.env.REACT_APP_PATH_IMAGES;

export const APPLITE_CAISSE = process.env.REACT_APP_APPLITE_CAISSE;

export const ENABLE_QT = process.env.REACT_APP_ENABLE_QT;

export const COLORS = [
  "#808000",
  "#6B8E23",
  "#FFA500",
  "#FF4500",
  "#DA70D6",
  "#EEE8AA",
  "#98FB98",
  "#AFEEEE",
  "#DB7093",
  "#FFDAB9",
  "#CD853F",
  "#FFC0CB",
  "#DDA0DD",
  "#B0E0E6",
  "#800080",
  "#663399",
  "#FF0000",
  "#BC8F8F",
  "#4169E1",
  "#8B4513",
  "#FA8072",
  "#F4A460",
  "#2E8B57",
  "#FFF5EE",
  "#A0522D",
  "#C0C0C0",
  "#87CEEB",
  "#6A5ACD",
  "#708090",
  "#708090",
  "#00FF7F",
  "#4682B4",
  "#D2B48C",
  "#008080",
  "#D8BFD8",
  "#FF6347",
  "#40E0D0",
  "#EE82EE",
  "#F5DEB3",
  "#FFFF00",
  "#9ACD32",
  "#FFF8DC",
  "#DC143C",
  "#00FFFF",
  "#00008B",
  "#008B8B",
  "#B8860B",
];

export const TVA = [0, 5, 7, 8,9, 10,11, 14, 18, 19, 20];

export const getCodeBar = () => {
  return Math.floor(Math.random() * 900000000) + 1000000000;
};

export const Disconnected = () => {
  ls.clear();
  window.location.reload();
};
var cryptr = new Cryptr(SECRET_KEY_ENCRYPT);

export var CheckSTATUS = async (response) => {
  var cryptr = new Cryptr(SECRET_KEY_ENCRYPT);

  let token = response.data?.token;
  let flagcataloge = response.data?.flagcataloge;
  let afficher_catalogue = response.data?.afficher_catalogue || 0 ;
  let afficher_tableau_de_bord = response.data?.afficher_tableau_de_bord || 0;
  let afficher_client = response.data?.afficher_client || 0;
  let afficher_gestion_de_stock = response.data?.afficher_gestion_de_stock || 0;
  let afficher_bon_commande = response.data?.afficher_bon_commande || 0;
  let afficher_inventaire = response.data?.afficher_inventaire || 0;
  let afficher_lalivraison = response.data?.afficher_lalivraison || 0;
  let afficher_facturation = response.data?.afficher_facturation || 0;
  let afficher_facture_fournisseur = response.data?.afficher_facture_fournisseur || 0;
  let afficher_devis = response.data?.afficher_devis || 0;
  let password = response.data?.token_pass;
  let check_date_ = response.data?.check_date_;
  let temporary_password = response.data?.temporary_password;
  let afficher_deliverect = response.data?.afficher_deliverect || 0;
  let afficher_cheques = response.data?.afficher_cheques || 0;
  let afficher_privileges = response.data?.afficher_privileges || 0;
  let afficher_enseigne = response.data?.afficher_enseigne|| 0;
  let disabled_bl_prix_achat = response.data?.disabled_bl_prix_achat || 0;
  let groupeclients = response.data?.groupeclients;
  let reglesdereduction = response.data?.reglesdereduction || 0;
  let reglesdeprix = response.data?.reglesdeprix || 0;
  let channel_manager = response.data?.channel_manager || 0;
  // Store the token
  ls.set("flagcataloge", flagcataloge);
  ls.set("afficher_catalogue", afficher_catalogue);
  ls.set("afficher_tableau_de_bord", afficher_tableau_de_bord);
  ls.set("afficher_client", afficher_client);
  ls.set("afficher_gestion_de_stock", afficher_gestion_de_stock);
  ls.set("afficher_bon_commande", afficher_bon_commande);
  ls.set("afficher_inventaire", afficher_inventaire);
  ls.set("afficher_lalivraison", afficher_lalivraison);
  ls.set("afficher_facturation", afficher_facturation);
  ls.set("afficher_facture_fournisseur", afficher_facture_fournisseur);
  ls.set("afficher_devis", afficher_devis);

  //channel manager
  ls.set("channel_manager", channel_manager);

  ls.set("afficher_deliverect", afficher_deliverect);
  ls.set("afficher_cheques", afficher_cheques);
  ls.set(
    "afficher_privileges",
    afficher_privileges == undefined ? 0 : afficher_privileges
  );
  ls.set("afficher_enseigne", cryptr.encrypt(afficher_enseigne));
  ls.set("disabled_bl_prix_achat", cryptr.encrypt(disabled_bl_prix_achat));
  ls.set("groupeclients", groupeclients);
  ls.set("reglesdereduction", reglesdereduction);
  ls.set("reglesdeprix", reglesdeprix);
  if (password !== null || password != "" || password !== "undefined") {
    let hex_password = md5.hex_md5(cryptr.decrypt(ls.get("password")));

    if (
      password != hex_password &&
      temporary_password == false &&
      check_date_ == "primary"
    ) {
      window.location.href = "/motdepassechange";
    }
    if (
      password != hex_password &&
      temporary_password == true &&
      check_date_ == "true"
    ) {
      window.location.href = "/motdepassechange";
    }
    if (check_date_ == "false" && password == hex_password) {
      window.location.href = "/motdepasseexpere";
    }
  }
};

var cryptr_ = new Cryptr(SECRET_KEY_ENCRYPT);

var defaut = cryptr_.encrypt(0);
export const DISABLED_BL_PRIX_ACHAT = cryptr_.decrypt(
  ls.get("disabled_bl_prix_achat") == null
    ? defaut
    : ls.get("disabled_bl_prix_achat")
);
